import React, { useState, useEffect, useCallback, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import StaticData from './StaticData';
import { classNames } from 'utils';
import { Button } from '@asu/components-core/dist/libCore.es';
import { Modal } from 'react-bootstrap';
import Component from './Component';
import { useMutation, useQuery } from '@tanstack/react-query';
import isEqual from 'lodash/isEqual';
import useServiceAuth from 'auth/useServiceAuth';
import { setAll } from 'state/slices/templateSlice';
import {
  setChecksheetAll,
  setChecksheetRequirementList,
} from 'state/slices/checksheetSlice';
import { setValidationAll } from 'state/slices/validationSlice';
import { checksheetValidationGet } from 'apis/validationAPIs';
import {
  checksheetGet,
  checksheetUpdate,
  checksheetUpdateProgress,
  checksheetLock,
} from 'apis/checksheetAPIs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheckCircle,
  faCircle,
  faLock,
} from '@fortawesome/free-solid-svg-icons';
import ProgressUpdateForm from './ProgressUpdateForm';

const statusCircle = {
  DEPARTMENT: {
    color: 'text-gray-4',
  },
  COLLEGE: {
    color: 'text-gray-7',
  },
  PROVOST: {
    color: 'text-warning',
  },
  DARS: {
    color: 'text-info',
  },
  COMPLETE: {
    color: 'text-success',
  },
};

const ChecksheetForm = ({ checkId, program, isLocked, holdsLock }) => {
  const { getAccessToken } = useServiceAuth();
  const dispatch = useDispatch();

  const idArray = checkId.split('*');
  const paramAcadSubplan = idArray[3];
  const owners = [];
  let mainOwner = {};
  let componentIdNames = [];

  const [previousReview, setPreviousReview] = useState('');
  const [nextReview, setNextReview] = useState('');
  const [subplanName, setSubplanName] = useState('');
  const [error, setError] = useState('');
  const [activeTab, setActiveTab] = useState(0);
  const [readOnly, setReadOnly] = useState(false);
  const [isValid, setIsValid] = useState(null);
  const [preventLock, setPreventLock] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalView, setModalView] = useState('');
  const [dataToModal, setDataToModal] = useState({});
  const [templateNotFound, setTemplateNotFound] = useState(false);

  const role = useSelector((state) => state.user.role);
  const userCollege = useSelector((state) => state.user.college);
  const userDepartment = useSelector((state) => state.user.department);

  const checksheetId = useSelector((state) => state.checksheet.checksheetId);
  const checksheetName = useSelector(
    (state) => state.checksheet.checksheetName
  );
  const year = useSelector((state) => state.checksheet.year);
  const programType = useSelector((state) => state.checksheet.programType);
  const acadplan = useSelector((state) => state.checksheet.acadplan);
  const acadsubplan = useSelector((state) => state.checksheet.acadsubplan);
  const college = useSelector((state) => state.checksheet.college);
  const department = useSelector((state) => state.checksheet.department);
  const checksheetRequirementList = useSelector(
    (state) => state.checksheet.checksheetRequirementList
  );
  const courseLists = useSelector((state) => state.checksheet.courseLists);
  const progress = useSelector((state) => state.checksheet.progress);
  const lockedBy = useSelector((state) => state.checksheet.lockedBy);

  const templateId = useSelector((state) => state.template.templateId);
  const templateComponentIds = useSelector(
    (state) => state.template.templateComponentIds
  );
  const templateComponentObjects = useSelector(
    (state) => state.template.templateComponentObjects
  );

  if (
    templateComponentObjects &&
    Object.keys(templateComponentObjects).length > 0
  ) {
    Object.keys(templateComponentObjects).forEach((key) => {
      // Get the component object using the current key
      const componentObject = templateComponentObjects[key];

      // Parse the details of the component object to extract the componentName
      const details = JSON.parse(componentObject.details);
      const componentId = componentObject.sk;
      const componentName = details.componentName;
      const component = {
        componentId: componentId,
        componentName: componentName,
      };
      // Add componentName to the array
      componentIdNames.push(component);
    });

    // Now componentNames array contains all the component names
    console.log('Component Names:', componentIdNames);
  }

  if (!!program?.owners?.length) {
    let highestPercent = 0;
    program.owners.forEach((programOwner) => {
      if (
        owners.filter(
          (owner) => owner.collegeAcadOrg === programOwner.collegeAcadOrg
        ).length === 0
      ) {
        owners.push({
          collegeAcadOrg: programOwner.collegeAcadOrg,
          collegeDescription: programOwner.collegeDescription,
          departmentAcadOrg: programOwner.departmentAcadOrg,
          departmentDescription: programOwner.departmentDescription,
        });
      }

      if (programOwner.percentOwned > highestPercent) {
        highestPercent = programOwner.percentOwned;
        mainOwner = programOwner;
      }
    });
  }

  const handleOpenModal = (view) => {
    setModalView(view);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setModalView('');
    setShowModal(false);
  };

  const {
    mutate: updateChecksheet,
    error: updateChecksheetError,
    isError: updateChecksheetIsError,
  } = useMutation({
    mutationFn: checksheetUpdate,
    onSuccess: () => {
      console.log('Checksheet updated successfully');
      refreshValidation();
    },
    onError: (error) => {
      console.error('Error updating checksheet:', error);
    },
  });

  const {
    mutate: updateChecksheetProgress,
    // error: updateChecksheetProgressError,
  } = useMutation({
    mutationFn: checksheetUpdateProgress,
    onSuccess: (data, { token }) => {
      lockChecksheet({
        id: checkId,
        jsonData: { action: 'unlock' },
        token,
      });
    },
    onError: () => {
      setPreventLock(false);
    },
  });

  const {
    data: checksheetData,
    error: getChecksheetError,
    isError: getChecksheetIsError,
    isSuccess: getChecksheetIsSuccess,
    isPending,
    fetchStatus,
    refetch: refetchChecksheet,
  } = useQuery({
    queryKey: ['checksheet', checkId],
    queryFn: async () => {
      const token = await getAccessToken();
      return checksheetGet({ id: checkId, token });
    },
    enabled: !!checkId,
  });

  // const {
  //   data: templateData,
  //   error: getTemplateError,
  //   isError: getTemplateIsError,
  //   isSuccess: getTemplateIsSuccess,
  //   refetch: refetchTemplate,
  // } = useQuery({
  //   queryKey: ['template', templateId],
  //   queryFn: async () => {
  //     const token = await getAccessToken();
  //     return templateGet({ id: templateId, token });
  //   },
  //   enabled: !!templateId,
  // });

  // const { mutate: fetchProgram } = useMutation({
  //   mutationFn: fetchDplUndergradProgramsByAcadPlanCode,
  //   onSuccess: (data) => {
  //     setProgram(data);
  //     if (!!data.subplans?.length) {
  //       const foundSubplan = data.subplans.find(
  //         (subplan) => subplan.acadSubPlanCode === temporaryAcadsubplan
  //       );

  //       if (foundSubplan) {
  //         setSubplanName(foundSubplan.description);
  //       }
  //     }
  //   },
  // });

  const {
    data: validationData,
    error: getValidationError,
    isError: getValidationIsError,
    isSuccess: getValidationIsSuccess,
    refetch: refetchValidation,
  } = useQuery({
    queryKey: ['validation', checksheetId],
    queryFn: async () => {
      const token = await getAccessToken();
      return checksheetValidationGet({ id: checksheetId, token });
    },
    enabled: false,
  });

  const {
    mutate: lockChecksheet,
    error: lockChecksheetError,
    isError: lockChecksheetIsError,
  } = useMutation({
    mutationFn: checksheetLock,
    onSuccess: () => {
      console.log('Checksheet locked successfully');
      refetchChecksheet();
      refetchValidation();
      setPreventLock(false);
    },
    onError: () => {
      setPreventLock(false);
    },
  });

  const handleLockUnlock = async (action) => {
    setPreventLock(true);

    const jsonData = {
      action,
    };

    const token = await getAccessToken();
    await lockChecksheet({
      id: checkId,
      jsonData,
      token,
    });

    if (lockChecksheetIsError) setError(lockChecksheetError.message);
  };

  // click Edit button to open modal and edit the data
  const openEditModal = (index, type, details, componentId, subsectionId) => {
    const modalData = {
      index: index,
      type: type,
      componentId: componentId,
      details: details,
      subsectionId: subsectionId,
    };

    setDataToModal(modalData);
    console.log('modal data:', modalData);
  };

  const refreshValidation = useCallback(() => {
    if (checksheetId && checksheetId !== '') {
      refetchValidation();

      let number = 0;

      const getGeneralStudiesValidationList = (
        objValidGSHours,
        objValidGSUpperDivisionHours,
        objGSHours,
        objGSUpperDivisionHours
      ) => {
        return Object.entries(objValidGSHours).map(([key, value]) => {
          const gs_label =
            StaticData.gsList.find((item) => item.value === key)?.label || ''; // Find the gs label corresponding to the key
          const creditHours = {
            goal: value || 0,
            actual: objGSHours[key] || 0,
          };
          const upperDivisionHours = {
            goal: objValidGSUpperDivisionHours[key] || 0,
            actual: objGSUpperDivisionHours[key] || 0,
          };

          if (
            creditHours.actual >= creditHours.goal &&
            upperDivisionHours.actual >= upperDivisionHours.goal
          ) {
            number++;
          }
          return {
            [key]: { creditHours, upperDivisionHours, gs_label }, // Adding gs_label property to the value
          };
        });
      };

      console.log('getValidationIsSuccess: ', getValidationIsSuccess);

      if (getValidationIsError) {
        setError(getValidationError.message);
      }

      if (getValidationIsSuccess) {
        console.log('validationDataaaaa: ', validationData);

        if (validationData) {
          const generalStudiesList = getGeneralStudiesValidationList(
            validationData.validGSHours,
            validationData.validGSUpperDivisionHours,
            validationData.totalGSHours,
            validationData.totalGSUpperDivisionHours
          );

          dispatch(
            setValidationAll({
              checksheetId: checksheetId,
              totalHours: validationData.totalHours,
              totalUpperDivisionHours: validationData.totalUpperDivisionHours,
              totalHoursPerSubsection: validationData.totalHoursPerSubsection,
              totalUpperDivisionHoursPerSubsection:
                validationData.totalUpperDivisionHoursPerSubsection,
              components: validationData.components,
              numberOfRulesValid: number,
              generalStudiesList: generalStudiesList,
              valid: validationData.valid,
            })
          );

          if (
            number === generalStudiesList.length &&
            (((!validationData.validMaxHours ||
              validationData.validMaxHours < validationData.validTotalHours) &&
              validationData.totalHours >= validationData.validTotalHours) ||
              (!!validationData.validMaxHours &&
                ((validationData.validMaxHours >=
                  validationData.validTotalHours &&
                  validationData.totalHours <= validationData.validMaxHours &&
                  validationData.totalHours >=
                    validationData.validTotalHours) ||
                  (validationData.validMaxHours ===
                    validationData.validTotalHours &&
                    validationData.totalHours ===
                      validationData.validTotalHours)))) &&
            validationData.totalUpperDivisionHours >=
              validationData.validUpperDivisionHours
          ) {
            setIsValid(true);
          } else {
            setIsValid(false);
          }
        }
      }
    }
  }, [
    checksheetId,
    refetchValidation,
    getValidationIsSuccess,
    getValidationIsError,
    getValidationError,
    validationData,
    dispatch,
  ]);

  // const receiveDataFromComponent = (data) => {
  //   console.log('Received data from component========:' + JSON.stringify(data));
  //   if (data) {
  //     updateNotes(data.checksheetComponentNotes, data.componentId);
  //   }
  // };

  // Function to update parent data from a child

  const updateData = async (
    index,
    type,
    newData,
    componentId,
    subsectionId,
    oldIndex,
    oldSubsectionId
  ) => {
    console.log(
      'updateData: ',
      index,
      type,
      newData,
      componentId,
      subsectionId,
      oldIndex,
      oldSubsectionId
    );

    console.log('checksheetRequirementList:::::', checksheetRequirementList);

    const subsections = checksheetRequirementList
      ? checksheetRequirementList[componentId]?.subsections
      : {};

    if (index === 9999 && subsections && subsections[subsectionId]) {
      index = subsections[subsectionId].requirements?.length || 0;
    } else if (index === 9999) {
      console.log('index: ', index);
      index = 0;
    }

    let updatedSubsections = { ...(subsections || {}) };
    console.log('subsections:', subsections);

    console.log('newdata::::', newData);

    let updatedDataList = subsections?.[subsectionId]?.requirements
      ? [...subsections[subsectionId].requirements]
      : [];

    if (
      !newData ||
      (typeof newData === 'object' && Object.keys(newData).length === 0) ||
      !isEqual(updatedDataList[index], newData)
    ) {
      if (
        !newData ||
        (typeof newData === 'object' && Object.keys(newData).length === 0)
      ) {
        // if newData is empty (delete data)
        updatedDataList.splice(index, 1);
      } else {
        // if new data is not empty, then update data
        updatedDataList[index] = newData;
      }
    }
    let updatedObject = {
      requirements: updatedDataList,
      subsectionName: subsections?.[subsectionId]?.subsectionName || '',
      hours: subsections?.[subsectionId]?.hours || 0,
      upperDivisionHours: subsections?.[subsectionId]?.upperDivisionHours || 0,
    };

    if (
      updatedObject.requirements.length === 0 &&
      updatedObject.subsectionName.trim() === ''
    ) {
      delete updatedSubsections[subsectionId];
    } else {
      updatedSubsections[subsectionId] = updatedObject;
    }

    //if we move a requirement from one subsection to anther, we want to remove it from the old subsection
    if (oldIndex !== 9999 && oldSubsectionId) {
      updatedDataList = updatedSubsections?.[oldSubsectionId]?.requirements
        ? [...updatedSubsections[oldSubsectionId].requirements]
        : [];

      updatedDataList.splice(oldIndex, 1);
      updatedObject = {
        requirements: updatedDataList,
        subsectionName:
          updatedSubsections?.[oldSubsectionId]?.subsectionName || '',
        hours: updatedSubsections?.[oldSubsectionId]?.hours || 0,
        upperDivisionHours:
          updatedSubsections?.[oldSubsectionId]?.upperDivisionHours || 0,
      };

      if (
        updatedObject.requirements.length === 0 &&
        updatedObject.subsectionName.trim() === ''
      ) {
        delete updatedSubsections[oldSubsectionId];
      } else {
        updatedSubsections[oldSubsectionId] = updatedObject;
      }
    }

    const next = {};

    let added = false;

    console.log('newSubsctions========', updatedSubsections);

    if (checksheetRequirementList) {
      for (const id in checksheetRequirementList) {
        if (checksheetRequirementList.hasOwnProperty(id)) {
          const item = checksheetRequirementList[id];
          if (componentId === id) {
            next[componentId] = { subsections: updatedSubsections };
            added = true;
            console.log('add new item to existing componentId: ', componentId);
          } else {
            console.log('add existing item to existing componentId: ', id);
            next[id] = { subsections: item.subsections };
          }
        }
      }
      if (!added) {
        // add new item to new component
        console.log('add new item to new componentId: ', componentId);
        next[componentId] = { subsections: updatedSubsections };
      }
    } else {
      next[componentId] = { subsections: updatedSubsections }; // if no requirements exist, then this is the first new item in the list
    }

    console.log('next=========', next);

    dispatch(setChecksheetRequirementList(next));
    const jsonData = {
      year: year.value,
      templateId: templateId,
      checksheetName: checksheetName,
      programType: programType,
      acadplan: acadplan,
      acadsubplan: acadsubplan,
      college: college,
      department: department,
      componentRequirements: next,
      courseLists: courseLists,
    };
    const token = await getAccessToken();
    const resp = await updateChecksheet({
      id: checksheetId,
      jsonData: jsonData,
      token: token,
    });
    console.log('resp:::::', resp);

    if (updateChecksheetIsError) setError(updateChecksheetError.message);
  };

  const updateSubsection = async (data, subsectionId, componentId, action) => {
    console.log('Updating subsectionname...');
    console.log('subsection data ', data);

    const subsections = checksheetRequirementList
      ? checksheetRequirementList[componentId]?.subsections
      : {};

    const updatedSubsections = { ...(subsections || {}) };

    console.log('subsections:', subsections);

    const subId = subsectionId !== '999' ? subsectionId : uuidv4();

    const updatedDataList = subsections?.[subId]?.requirements
      ? [...subsections[subId].requirements]
      : [];

    const updatedObject = {
      requirements: updatedDataList,
      subsectionName:
        action === 'subsectionName'
          ? data
          : subsections?.[subId]?.subsectionName || '',
      hours: action === 'hours' ? data : subsections?.[subId]?.hours || 0,
      upperDivisionHours:
        action === 'upperDivisionHours'
          ? data
          : subsections?.[subId]?.upperDivisionHours || 0,
    };

    updatedSubsections[subId] = updatedObject;

    console.log('new subsection data: ', updatedSubsections);

    const next = {};

    let added = false;

    if (checksheetRequirementList) {
      for (const id in checksheetRequirementList) {
        if (checksheetRequirementList.hasOwnProperty(id)) {
          const item = checksheetRequirementList[id];
          if (componentId === id) {
            next[componentId] = { subsections: updatedSubsections };
            added = true;
            console.log('add new item to existing componentId: ', componentId);
          } else {
            console.log('add existing item to existing componentId: ', id);
            next[id] = { subsections: item.subsections };
          }
        }
      }
      if (!added) {
        // add new item to new component
        console.log('add new item to new componentId: ', componentId);
        next[componentId] = { subsections: updatedSubsections };
      }
    } else {
      next[componentId] = { subsections: updatedSubsections }; // if no requirements exist, then this is the first new item in the list
    }

    console.log('next=========', next);

    dispatch(setChecksheetRequirementList(next));
    const jsonData = {
      year: year.value,
      templateId: templateId,
      checksheetName: checksheetName,
      programType: programType,
      acadplan: acadplan,
      acadsubplan: acadsubplan,
      college: college,
      department: department,
      componentRequirements: next,
      courseLists: courseLists,
    };

    console.log(jsonData);
    const token = await getAccessToken();
    updateChecksheet({ id: checksheetId, jsonData: jsonData, token: token });

    if (updateChecksheetIsError) setError(updateChecksheetError.message);
  };

  const handleProgressUpdate = async (message, action, updatedProgress) => {
    const jsonData = {
      progress: updatedProgress,
      action,
      note: message,
    };
    const token = await getAccessToken();

    await updateChecksheetProgress({
      id: checksheetId,
      jsonData: jsonData,
      token: token,
    });
  };

  // const updateNotes = async (newData, componentId) => {
  //   const requirements =
  //     checksheetRequirementList && checksheetRequirementList[componentId]
  //       ? checksheetRequirementList[componentId].requirements
  //       : [];

  //   const next = {};
  //   const newObj = {
  //     notes: newData,
  //     requirements: requirements,
  //   };

  //   var added = false;

  //   if (checksheetRequirementList) {
  //     for (const id in checksheetRequirementList) {
  //       if (checksheetRequirementList.hasOwnProperty(id)) {
  //         const item = checksheetRequirementList[id];
  //         if (componentId === id) {
  //           next[componentId] = newObj;
  //           added = true;
  //         } else {
  //           next[id] = item;
  //         }
  //       }
  //     }
  //     if (added === false) {
  //       // add new item to new component
  //       next[componentId] = newObj;
  //     }
  //   } else {
  //     next[componentId] = newObj; // if no requirements exist, then this is the first new item in the list
  //   }

  //   dispatch(setChecksheetRequirementList(next));
  //   const jsonData = {
  //     year: year.value,
  //     templateId: templatetemplateId,
  //     checksheetName: checksheetName,
  //     programType: programType,
  //     acadplan: acadplan,
  //     acadsubplan: acadsubplan,
  //     college: college,
  //     department: department,
  //     componentRequirements: next,
  //     courseLists: courseLists,
  //   };
  //   console.log('JSON: ', jsonData);
  //   const token = await getAccessToken();
  //   updateChecksheet({ id: checksheetId, jsonData: jsonData, token: token });

  //   if (updateChecksheetIsError) setError(updateChecksheetError.message);
  // };

  useEffect(() => {
    const progressReviewObj = StaticData.progressReview.find(
      (item) => item.review === progress
    );

    if (progressReviewObj) {
      setPreviousReview(progressReviewObj.previousReview);
      setNextReview(progressReviewObj.nextReview);
    }
  }, [progress]);

  useEffect(() => {
    if (!!program.subplans?.length) {
      const foundSubplan = program.subplans.find(
        (subplan) => subplan.acadSubPlanCode === paramAcadSubplan
      );

      if (foundSubplan) {
        setSubplanName(foundSubplan.description);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [program]);

  useEffect(() => {
    if (checkId !== '' && checkId !== null) {
      if (getChecksheetIsError) setError(getChecksheetError.message);
      console.log('getChecksheetIsSuccess: ', getChecksheetIsSuccess);
      if (getChecksheetIsSuccess) {
        console.log('checksheetData: ', JSON.stringify(checksheetData));

        // if checksheet exist
        if (checksheetData) {
          const jsonDetails = JSON.parse(checksheetData['details']);
          const yearValue = StaticData.yearList.find(
            (obj) => obj.value === jsonDetails.year
          );
          console.log('yearvalue: ', yearValue);
          console.log(
            'jsonDetails.componentRequirements: ',
            jsonDetails.componentRequirements
          );

          let lockedOrUnlocked = {};

          if (!!checksheetData['lockedBy']) {
            lockedOrUnlocked = JSON.parse(checksheetData['lockedBy']);
          }

          dispatch(
            setChecksheetAll({
              checksheetId: checksheetData['sk'],
              checksheetName: jsonDetails.checksheetName,
              year: yearValue,
              templateId: jsonDetails.templateId,
              programType: jsonDetails.programType,
              acadplan: jsonDetails.acadplan,
              acadsubplan: jsonDetails.acadsubplan,
              // acadplanDescription: jsonDetails.acadplanDescription,
              college: jsonDetails.college,
              department: jsonDetails.department,
              checksheetRequirementList: jsonDetails.componentRequirements,
              progress: checksheetData['progress'],
              lockedBy: lockedOrUnlocked,
              courseLists: jsonDetails.courseLists,
              details: jsonDetails,
            })
          );

          if (checksheetData?.templateObject.details) {
            const templateJsonDetails = JSON.parse(
              checksheetData.templateObject['details']
            );
            const templateYearValue = StaticData.yearList.find(
              (obj) => obj.value === templateJsonDetails.year
            );

            dispatch(
              setAll({
                templateId: checksheetData.templateObject['sk'],
                year: templateYearValue,
                templateName: templateJsonDetails.templateName,
                templateSettings: templateJsonDetails.settings,
                templateComponentIds: templateJsonDetails.components,
                templateComponentObjects:
                  checksheetData.templateObject['componentObjects'],
                templateValidationList: templateJsonDetails.validationRules,
                templateTextOptions: templateJsonDetails.textOptions,
                templateMilestones: templateJsonDetails.milestones,
              })
            );
          } else setTemplateNotFound(true);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getChecksheetIsSuccess, checksheetData, checkId]);

  // useEffect(() => {
  //   if (getTemplateIsError) setError(getTemplateError.message);

  //   if (getTemplateIsSuccess) {
  //     console.log('templateData: ', templateData);

  //     const jsonDetails = JSON.parse(templateData['details']);
  //     const yearValue = StaticData.yearList.find(
  //       (obj) => obj.value === jsonDetails.year
  //     );

  //     dispatch(
  //       setAll({
  //         templateId: templateData['sk'],
  //         year: yearValue,
  //         templateName: jsonDetails.templateName,
  //         templateSettings: jsonDetails.settings,
  //         templateComponentIds: jsonDetails.components,
  //         templateComponentObjects: templateData['componentObjects'],
  //         templateValidationList: jsonDetails.validationRules,
  //         templateTextOptions: jsonDetails.textOptions,
  //         templateMilestones: jsonDetails.milestones,
  //       })
  //     );
  //   }
  // }, [
  //   getTemplateIsSuccess,
  //   templateId,
  //   templateData,
  //   dispatch,
  //   getTemplateError,
  //   getTemplateIsError,
  // ]);

  // useEffect(() => {
  //   if (!!templateId) refetchTemplate();
  // }, [refetchTemplate, templateId]);

  useEffect(() => {
    refreshValidation();
  }, [refreshValidation]);

  useEffect(() => {
    let findOwner = false;

    if (program.owners && program.owners.length > 0) {
      program.owners.forEach((programOwner) => {
        if (
          userCollege === programOwner.collegeAcadOrg ||
          userDepartment.includes(programOwner.departmentAcadOrg) ||
          role.includes('PROVOST') ||
          role.includes('DARS')
        ) {
          findOwner = true;
        }
      });
    }
    if (findOwner === false) {
      setReadOnly(true);
    } else {
      if (role.includes(progress)) {
        setReadOnly(false);
      }
    }
  }, [program, userCollege, userDepartment, readOnly, role, progress]);

  if (checkId && isPending && fetchStatus === 'fetching')
    return <div className="container">Loading...</div>;

  if (
    // getTemplateIsError ||
    getChecksheetIsError ||
    // fetchProgramsIsError ||
    getValidationIsError ||
    updateChecksheetIsError
  )
    return (
      <div>
        <div>An error has occurred: {error}</div>
        <div>
          Please refresh the page. If the error persists, please try again
          later.
        </div>
      </div>
    );

  return (
    <>
      <div>
        <div
          className="template-header bg-white border-bottom border-gray-4 position-fixed"
          style={{ width: '80%', zIndex: 1 }}
        >
          {checksheetData && (
            <div
              className={classNames(
                isValid &&
                  ((role.includes(progress) &&
                    progress !== 'PROVOST' &&
                    progress !== 'DARS') ||
                    progress === 'COMPLETE')
                  ? 'bg-success-custom'
                  : 'bg-gray-1 border-bottom border-gray-4',
                'd-flex py-1'
              )}
              style={{ width: '100%' }}
            >
              <div className="container d-flex justify-content-between align-items-center">
                {isValid ? (
                  progress === 'COMPLETE' ? (
                    <div className="fw-bold" style={{ padding: '5px 0' }}>
                      The checksheet is complete!
                    </div>
                  ) : !!progress && !role.includes(progress) ? (
                    <div className="fw-bold" style={{ padding: '5px 0' }}>
                      The checksheet is pending{' '}
                      {
                        StaticData.statusRoles.find(
                          (statusRole) => statusRole.value === progress
                        ).label
                      }{' '}
                      review.
                    </div>
                  ) : role.includes('PROVOST') || role.includes('DARS') ? (
                    <div className="fw-bold" style={{ padding: '5px 0' }}>
                      This checksheet is pending approval.
                    </div>
                  ) : !!nextReview ? (
                    <div
                      className="d-flex gap-1 align-items-center"
                      style={{ padding: '5px 0' }}
                    >
                      <FontAwesomeIcon
                        icon={faCheckCircle}
                        className="text-success-custom"
                      />
                      <div className="fw-bold">
                        Checksheet satisfies all rules and may be submitted for{' '}
                        {
                          StaticData.statusRoles.find(
                            (statusRole) => statusRole.value === nextReview
                          ).label
                        }{' '}
                        review.
                      </div>
                    </div>
                  ) : (
                    <div className="fw-bold" style={{ padding: '5px 0' }}>
                      This checksheet has been completed
                    </div>
                  )
                ) : (
                  <div className="fw-bold" style={{ padding: '5px 0' }}>
                    This checksheet does not meet the validation requirements
                  </div>
                )}
                <div className="d-flex gap-2 align-items-center">
                  {role.includes(progress) &&
                    !!previousReview &&
                    isLocked &&
                    holdsLock &&
                    (role.includes('DARS') ||
                      role.includes('PROVOST') ||
                      (role.includes('COLLEGE') &&
                        userCollege === college)) && (
                      <div
                        className="button-link"
                        onClick={() => handleOpenModal('return')}
                        role="button"
                      >
                        Return checksheet
                      </div>
                    )}
                  {role.includes(progress) &&
                    !!nextReview &&
                    isValid &&
                    (isLocked && holdsLock ? (
                      progress === 'PROVOST' || progress === 'DARS' ? (
                        <Button
                          label="Approve"
                          color="gold"
                          size="small"
                          icon={['fa', 'check']}
                          onClick={() => handleOpenModal('submit')}
                        />
                      ) : (
                        <Button
                          label="Submit"
                          color="dark"
                          size="small"
                          onClick={() => handleOpenModal('submit')}
                        />
                      )
                    ) : (
                      <div>
                        Checksheet must be locked before{' '}
                        {progress === 'PROVOST' || progress === 'DARS'
                          ? 'approval'
                          : 'submission'}
                      </div>
                    ))}
                </div>
              </div>
            </div>
          )}
          <div className="container mt-2">
            <div className="row">
              <div className="col-6">
                {readOnly && checksheetData && (
                  <div className="text-maroon">(Read Only)</div>
                )}
                <div className="fs-4 fw-bold">{checksheetName}</div>
                {!!subplanName && (
                  <div className="fw-bold mb-1">
                    <span>{subplanName}</span>
                    <span className="subplan-tag ms-1">Subplan</span>
                  </div>
                )}
                <div style={{ fontSize: '14px' }}>
                  {owners &&
                    owners.map((owner) => (
                      <div key={owner.collegeAcadOrg}>
                        <span>
                          <span>{owner.collegeDescription} / </span>
                          {/* {owner.departmentDescription} ({owner.departmentAcadOrg}) */}
                          <span>{acadplan} / </span>
                          {paramAcadSubplan !== 'null' && (
                            <span>{paramAcadSubplan} / </span>
                          )}
                          <span>{year.label}</span>
                        </span>
                      </div>
                    ))}
                </div>
              </div>
              <div className="col-6">
                <div>
                  <div
                    className="d-flex gap-3 align-items-center justify-content-end mb-1"
                    style={{ padding: '6px 0' }}
                  >
                    {progress && (
                      <div className="d-flex gap-1 align-items-center fw-bold">
                        <FontAwesomeIcon
                          icon={faCircle}
                          className={classNames(statusCircle[progress].color)}
                          width={'16px'}
                        />
                        <div>
                          {
                            StaticData.statusList.find(
                              (status) => status.value === progress
                            ).label
                          }
                        </div>
                      </div>
                    )}
                    {lockedBy && !!Object.keys(lockedBy).length && (
                      <div className="d-flex gap-1 align-items-center bg-gray-2 px-1 rounded-1">
                        <FontAwesomeIcon
                          icon={faLock}
                          className="text-gray-6"
                          style={{ width: '12px' }}
                        />
                        <div>Locked by {lockedBy.asurite}</div>
                      </div>
                    )}
                  </div>
                  <div className="d-flex gap-3 align-items-center justify-content-end">
                    {!!previousReview &&
                      role.includes(previousReview) &&
                      (role.includes('PROVOST') ||
                        (role.includes('COLLEGE') && userCollege === college) ||
                        (role.includes('DEPARTMENT') &&
                          userDepartment.includes(department))) &&
                      !isLocked && (
                        <div className="text-end">
                          <div
                            className="button-link"
                            onClick={() => handleOpenModal('recall')}
                            role="button"
                          >
                            Recall submission
                          </div>
                        </div>
                      )}

                    {isLocked && (holdsLock || role.includes('PROVOST')) && (
                      <Button
                        label="Unlock"
                        size="small"
                        icon={['fa', 'unlock']}
                        classes={[
                          'btn btn-white btn-md border border-maroon text-maroon',
                        ]}
                        onClick={() => handleLockUnlock('unlock')}
                        disabled={preventLock}
                      />
                    )}

                    {!isLocked &&
                      !!progress &&
                      role.includes(progress) &&
                      (role.includes('DARS') ||
                        role.includes('PROVOST') ||
                        (role.includes('COLLEGE') && userCollege === college) ||
                        (role.includes('DEPARTMENT') &&
                          userDepartment.includes(department))) && (
                        <Button
                          label="Lock"
                          size="small"
                          color="maroon"
                          icon={['fa', 'lock']}
                          onClick={() => handleLockUnlock('lock')}
                          disabled={preventLock}
                        />
                      )}
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex gap-3">
              {checksheetData &&
                componentIdNames.map((componentIdName, index) => (
                  <a
                    key={index}
                    href={`#${componentIdName.componentId}`}
                    onClick={() => setActiveTab(index)}
                    className={classNames(
                      activeTab === index
                        ? 'active-tab fw-bold'
                        : 'inactive-tab',
                      'pt-2 pb-1 d-flex justify-content-center text-gray-7 text-decoration-none'
                    )}
                  >
                    {componentIdName.componentName}
                  </a>
                ))}
            </div>
          </div>
        </div>
        {/* TODO: Might need to adjust padding based off of height of header, I need to think about this */}
        <div className="container" style={{ paddingTop: `250px` }}>
          <div className="row no-gutters">
            <div className="col uds-full-width">
              {!templateNotFound ? (
                <div className="pt-2">
                  {templateComponentIds.map((componentId) => (
                    <div key={componentId} className="mb-9" id={componentId}>
                      <Component
                        id={componentId}
                        year={year.value}
                        checksheet={true}
                        isLocked={isLocked && !role.includes('DARS')}
                        holdsLock={holdsLock}
                        openEditModal={openEditModal}
                        updateData={updateData}
                        dataToModal={dataToModal}
                        // dataToParent={(newData) =>
                        //   receiveDataFromComponent(newData)
                        // }
                        updateSubsection={updateSubsection}
                        readOnly={readOnly}
                      />
                    </div>
                  ))}
                </div>
              ) : (
                <div>
                  The template associated with this checksheet was not found.
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <Modal show={showModal} onHide={handleCloseModal}>
        <ProgressUpdateForm
          view={modalView}
          progress={progress}
          updatedProgress={
            modalView === 'recall' || modalView === 'return'
              ? previousReview
              : modalView === 'submit'
                ? nextReview
                : ''
          }
          college={college}
          department={department}
          mainOwner={mainOwner}
          handleProgressUpdate={handleProgressUpdate}
          handleClose={handleCloseModal}
        />
      </Modal>
    </>
  );
};

export default ChecksheetForm;
