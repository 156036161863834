import React, { useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { useMutation } from '@tanstack/react-query';
import isEqual from 'lodash/isEqual';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Button } from '@asu/components-core/dist/libCore.es';

import CourseListComponent from './CourseListComponent';

import useServiceAuth from 'auth/useServiceAuth';
import { checksheetUpdate } from 'apis/checksheetAPIs';

// import { setCourses, setCourseListId } from 'state/slices/courseListSlice';

// import { classNames } from 'utils';
import { setCourseLists, setDetails } from 'state/slices/checksheetSlice';

const CourseListForm = ({
  courseListId,
  handleClose,
  isLocked = false,
  holdsLock = false,
}) => {
  const { getAccessToken } = useServiceAuth();
  const [dataToModal, setDataToModal] = useState({});
  // const [courseItems, setCourseItems] = useState([]);
  const [submitCourseList, setSubmitCourseList] = useState(false);

  const checksheetId = useSelector((state) => state.checksheet.checksheetId);
  // const courseListId = useSelector((state) => state.courseList.courseListId);
  // const courseListName = useSelector(
  //   (state) => state.courseList.courseListName
  // );
  const details = useSelector((state) => state.checksheet.details);
  const requirementList = useSelector(
    (state) => state.checksheet.checksheetRequirementList
  );
  // const courses = useSelector((state) => state.courseList.courses);

  const courseLists = useSelector((state) => state.checksheet.courseLists);

  const [courseListName, setCourseListName] = useState(
    courseListId !== null ? courseLists[courseListId]?.courseListName : ''
  );
  const [courses, setCourses] = useState(
    courseListId !== null ? courseLists[courseListId]?.courses : []
  );

  console.log('checksheetId============', checksheetId);

  // const [showAsComponent, setShowAsComponent] = useState(true);
  // const [requirementType, setRequirementType] = useState(null);
  // const [newCourseListName, setNewCourseListName] = useState('');

  const [error, setError] = useState('');

  const dispatch = useDispatch();

  const {
    mutate: updateChecksheet,
    error: updateChecksheetError,
    isError: updateChecksheetIsError,
  } = useMutation({
    mutationFn: checksheetUpdate,
  });

  // Create a ref to courseListComponent to call child function
  const courseListComponentRef = useRef(null);
  const saveLastRequirement = () => {
    if (courseListComponentRef.current) {
      setSubmitCourseList(true);
      courseListComponentRef.current.callChildFunction(
        'Hello from courselist form'
      ); // Call child function
    }
  };

  const handleDeleteCourseList = () => {
    const confirmDeletion = window.confirm(
      'Are you sure you want to delete this course list?'
    );
    if (confirmDeletion) {
      console.log('delete courseListId:', courseListId);
      saveCourseList(null, '');
      handleClose();
    }
  };

  const saveCourseList = async (newCourses, newCourseListName) => {
    if (!courseListName && !newCourseListName) {
      alert('A courseList name is required');
      return;
    }

    try {
      const jsonData = details;
      console.log('jsonData:', jsonData);
      console.log('courseListId:', courseListId);
      let courseListUuid = courseListId || uuidv4();

      // Ensure courseLists is defined within jsonData
      let courseLists = jsonData.courseLists || {};

      // If courseLists is frozen, create a new object to prevent modification errors
      if (Object.isFrozen(courseLists)) {
        courseLists = { ...courseLists };
      }

      if (newCourses === null && courseLists[courseListId]) {
        // If newCourses is null, remove the course list
        delete courseLists[courseListId];
      } else {
        // Ensure courseListId is valid and exists within courseLists
        if (!courseLists[courseListUuid]) {
          // Initialize the course list if it doesn't exist
          courseLists[courseListUuid] = {
            courseListName: newCourseListName || courseListName,
            courses: courses || [],
          };
        }
        console.log('courseLists before update:', courseLists);

        const updatedCourses = new Set([...courses]);

        courseLists[courseListUuid] = {
          courseListName: newCourseListName || courseListName,
          courses: Array.from(newCourses || updatedCourses),
        };
      }

      console.log('courseLists after update:', courseLists);

      // Create updatedJsonData with the same structure as jsonData
      const updatedJsonData = {
        ...jsonData,
        componentRequirements: requirementList,
        courseLists: {
          // ...jsonData.courseLists,
          ...courseLists,
        },
      };

      dispatch(setCourseLists(courseLists));
      // dispatch(setCourseListId(courseListUuid));
      console.log('Updated JSON:', updatedJsonData);
      dispatch(setDetails(updatedJsonData));

      const token = await getAccessToken();
      await updateChecksheet({
        id: checksheetId,
        jsonData: updatedJsonData,
        token,
      });
    } catch (error) {
      console.error('Error saving course list:', error);
      setError('Failed to save course list. Please try again.');
    }
  };

  // click Edit button to open modal and edit the data
  const openEditModal = (index, type, details) => {
    const modalData = {
      index: index,
      type: type,
      details: details,
    };

    setDataToModal(modalData);
    // modalSetAsRequirement(type);
  };

  //add index and req type to data. We need to know the index when data sent back from child component
  //and what type of the requirment it is.
  const createWrappedData = (index, type, details) => {
    const wrappedData = {
      index: index,
      type: type,
      details: details,
    };
    return wrappedData;
  };

  // Callback function to receive data from the modal
  const receiveDataFromModal = (data) => {
    console.log('Received data from modal:' + JSON.stringify(data));
    if (data) {
      if (
        !data.details ||
        !data.details.courses ||
        data.details.courses.length === 0
      ) {
        updateData(data.index, data.type, null);
      } else updateData(data.index, data.type, data.details);
    }
  };

  // Function to update parent data from a child
  const updateData = (index, type, newData) => {
    if (
      type === 'req_multiple' ||
      type === 'req_course' ||
      type === 'req_gs' ||
      type === 'req_text'
    ) {
      console.log('comonmentRequirementList+++++++', courses);

      if (index === 9999 && courses) {
        index = courses.length;
      } else if (index === 9999) {
        index = 0;
      }
      const newDataList = [...courses];
      if (!newData || !isEqual(newDataList[index], newData)) {
        if (!newData) {
          // if newData is empty (delete data)
          newDataList.splice(index, 1);
        } else {
          // if new data is not empty, then update data
          newDataList[index] = newData;
        }
      }

      console.log('new data list: ', newDataList);
      // dispatch(setCourses(newDataList));
      setCourses(newDataList);

      saveCourseList(newDataList, courseListName); // save course list data to database
      if (updateChecksheetIsError) {
        setError(updateChecksheetError.message);
      }
    }
  };

  // when user click the save button, save the course list first and then submit the course list
  const handleSaveCourseList = () => {
    if (!courseListName) {
      alert('A courseList name is required');
      return;
    }
    saveLastRequirement();
  };

  if (submitCourseList) {
    saveCourseList(courses, courseListName);
    setSubmitCourseList(false);
    handleClose();
  }

  // useEffect(() => {
  //   console.log('Setting courses...');
  //   setCourseItems(courses);
  // }, [courses]);

  if (updateChecksheetIsError)
    return (
      <div>
        <div>An error has occurred: {error}</div>
        <div>
          Please refresh the page. If the error persists, please try again
          later.
        </div>
      </div>
    );

  return (
    <div style={{ width: '1224px' }}>
      <div className="px-6 pb-3 pt-6">
        {isLocked && holdsLock ? (
          <div className="mb-6">
            <div className="fs-4 fw-bold">Course list name</div>
            <input
              type="text"
              placeholder="Untitled course list"
              className="fs-4 fw-bold px-2 py-1 w-100"
              name="courseListId"
              value={courseListName}
              onChange={(e) => setCourseListName(e.target.value)}
              // onBlur={(e) => handleComponentNameBlur(e)}
            />
          </div>
        ) : (
          <div className="fs-3 fw-bold mb-6">{courseListName}</div>
        )}
        <CourseListComponent
          ref={courseListComponentRef}
          id={checksheetId}
          year={details.year}
          courseListId={courseListId}
          // courseListName={courseListName}
          edit={true}
          openEditModal={openEditModal}
          updateData={updateData}
          dataFromParent={dataToModal}
          dataToParent={(newData) => receiveDataFromModal(newData)}
          createWrappedData={createWrappedData}
          // modalSetAsRequirement={modalSetAsRequirement}
          fromCourseList={true}
          // handleChangeReq={handleChangeReq}
          // handleChangeRule={handleChangeRule}
          courses={courses}
          setCourses={setCourses}
          handleClose={handleClose}
          isLocked={isLocked}
          holdsLock={holdsLock}
        />
      </div>

      <div className="px-6 py-3 bg-gray-1">
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex gap-1 align-items-center">
            {isLocked && holdsLock && (
              <Button
                label={'Save'}
                color="maroon"
                onClick={handleSaveCourseList}
              />
            )}
            <Button onClick={handleClose} label="Cancel" color="gray" />
          </div>
          {isLocked && holdsLock && (
            <div className="px-6">
              <span
                className="button-link"
                onClick={handleDeleteCourseList}
                role="button"
              >
                <FontAwesomeIcon icon={faTrash} className="me-1" />
                Delete list
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CourseListForm;
