import React, { useEffect } from 'react';
import ProgramList from 'components/ProgramList';
import { useQuery } from '@tanstack/react-query';
import { fetchDplColleges } from 'apis/dplAPIs';
import { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

const Programs = () => {
  const [collegeName, setCollegeName] = useState([]);
  const [error, setError] = useState(null);
  const location = useLocation();

  const [queryParams, setQueryParams] = useState({});

  const {
    data: fetchCollegesData,
    error: fetchCollegesError,
    isError: fetchCollegesIsError,
    isSuccess: fetchCollegesIsSuccess,
  } = useQuery({
    queryKey: ['colleges'],
    queryFn: fetchDplColleges,
  });

  useEffect(() => {
    if (fetchCollegesError) setError(fetchCollegesError.message);
    if (fetchCollegesIsSuccess) {
      const collegeFound = fetchCollegesData.find(
        (college) => college.acadOrgCode === queryParams.college
      );

      if (!!collegeFound) setCollegeName(collegeFound.alphaSortDescription);
    }
  }, [
    fetchCollegesData,
    fetchCollegesError,
    fetchCollegesIsSuccess,
    queryParams.college,
  ]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    console.log('params', params);
    const paramsObject = {};

    for (let [key, value] of params.entries()) {
      paramsObject[key] = value;
    }

    setQueryParams(paramsObject);
  }, [location.search]);

  if (fetchCollegesIsError)
    return (
      <div>
        <div>An error has occurred: {error}</div>
        <div>
          Please refresh the page. If the error persists, please try again
          later.
        </div>
      </div>
    );

  return (
    <div className="my-4">
      <div className="mb-3">
        <Link to={'/'} className="text-maroon">
          Home
        </Link>
        <span className="text-gray-5 mx-1">/</span>
        {queryParams.college ? collegeName : 'All colleges/schools'}
      </div>
      <ProgramList
        collegeCode={queryParams.college}
        programType={queryParams.program}
      />
    </div>
  );
};

export default Programs;
