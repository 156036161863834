import axios from 'axios';

export async function fetchDplUndergradPrograms() {
  const api =
    'https://api.myasuplat-dpl.asu.edu/api/codeset/acad-plans?degreeType=UG&filter=activeInDegreeSearch&include=*';
  console.log('api: ' + api);
  let res = [];

  await axios.get(api).then((response) => {
    res = response.data;
  });

  return res;
}

export async function fetchDplMinorsAndCertificates() {
  const api =
    'https://api.myasuplat-dpl.asu.edu/api/codeset/acad-plans?degreeType=UGCM&filter=activeInDegreeSearch&include=*';
  console.log('api: ' + api);
  let res = [];

  await axios.get(api).then((response) => {
    res = response.data;
  });

  return res;
}

export async function fetchDplUndergradProgramsByAcadPlanCode(acadplan) {
  const api =
    'https://api.myasuplat-dpl.asu.edu/api/codeset/acad-plan/' +
    acadplan +
    '?include=*';
  console.log('api: ' + api);
  let res = [];

  await axios.get(api).then((response) => {
    res = response.data;
    // console.log("fetchprograms res: ", res);
  });

  return res;
}

export async function fetchDplColleges() {
  const api =
    'https://api.myasuplat-dpl.asu.edu/api/codeset/colleges?include=childAcadOrgs';
  console.log('api: ' + api);
  let res = [];

  await axios.get(api).then((response) => {
    res = response.data;
  });

  return res;
}

export async function fetchDplAllPrograms() {
  const api = 'https://api.myasuplat-dpl.asu.edu/api/codeset/acad-plans';
  console.log('api: ' + api);
  let res = [];

  await axios.get(api).then((response) => {
    res = response.data;
  });

  return res;
}
