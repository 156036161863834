import React, { Fragment, useEffect, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { checksheetCopy, checksheetGetAllByYear } from 'apis/checksheetAPIs';
import { setChecksheetList } from 'state/slices/dataListsSlice';
import { Button } from '@asu/components-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBuildingColumns,
  faLocationDot,
  faXmark,
} from '@fortawesome/free-solid-svg-icons';
import StaticData from './StaticData';
import { classNames } from 'utils';
import useServiceAuth from 'auth/useServiceAuth';

const SearchProgram = ({ program }) => {
  const degree = program.degreeDescriptionShort
    ? program.degreeDescriptionShort === 'CERT'
      ? ', Certificate'
      : `, ${program.degreeDescriptionShort}`
    : program.acadPlanType === 'MIN'
      ? ', Minor'
      : null;

  const owners = [];
  if (program.owners.length > 0) {
    program.owners.forEach((programOwner) => {
      if (
        owners.filter(
          (owner) => owner.collegeAcadOrg === programOwner.collegeAcadOrg
        ).length === 0
      ) {
        owners.push({
          collegeAcadOrg: programOwner.collegeAcadOrg,
          collegeDescription: programOwner.collegeDescription,
        });
      }
    });
  }

  const campusesOffered = [];
  if (program.campusesOffered && program.campusesOffered.length > 0) {
    program.campusesOffered.forEach((campus) => {
      const obj = { ...campus };
      const campusFound = StaticData.campusUndergradList.find(
        (campusUndergrad) => campusUndergrad.value === campus.campusCode
      );
      if (campusFound?.label) obj.label = campusFound.label;
      campusesOffered.push(obj);
    });
  }

  return (
    <>
      <div className="d-flex gap-2 justify-content-between">
        <div className="fw-bold" style={{ fontSize: '18px' }}>
          {program.acadPlanDescription}
          {/* {program.subplan && ` - ${program.subplan.description}`} */}
          {degree}
        </div>
        <div style={{ marginTop: '3px' }}>{program.acadPlanCode}</div>
      </div>
      {program.subplan && (
        <div className="d-flex gap-2 justify-content-between">
          <div className="fw-bold">
            {program.subplan.description}
            <span className="subplan-tag ms-1">Subplan</span>
          </div>
          <div style={{ marginTop: '3px' }}>
            {program.subplan.acadSubPlanCode}
          </div>
        </div>
      )}
      <div className="d-flex gap-1 align-items-center">
        <FontAwesomeIcon
          icon={faBuildingColumns}
          className="text-gray-6"
          width={'16px'}
        />
        {owners.map((owner, index, array) => (
          <Fragment key={owner.collegeAcadOrg}>
            {owner.collegeDescription}
            {index !== array.length - 1 && ', '}
          </Fragment>
        ))}
      </div>
      <div className="d-flex gap-1 align-items-center">
        <FontAwesomeIcon
          icon={faLocationDot}
          className="text-gray-6"
          width={'16px'}
        />
        {campusesOffered.map((campus, index, array) => (
          <Fragment key={campus.campusCode}>
            {campus.label}
            {index !== array.length - 1 && ', '}
          </Fragment>
        ))}
      </div>
    </>
  );
};

const NewChecksheet = ({ handleClose }) => {
  const { getAccessToken } = useServiceAuth();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [searchString, setSearchString] = useState('');
  const [currentChecksheetDegree, setCurrentChecksheetDegree] = useState(null);
  const [selectedProgram, setSelectedProgram] = useState(null);
  const [selectedProgramDegree, setSelectedProgramDegree] = useState(null);
  const [selectedChecksheet, setSelectedChecksheet] = useState(null);
  const year = useSelector((state) => state.settings.year);
  const [selectedRadio, setSelectedRadio] = useState(null);

  const checksheetList = useSelector((state) => state.dataLists.checksheetList);
  const currentChecksheet = useSelector(
    (state) => state.currentSelection.currentChecksheet
  );
  const isModalOpen = useSelector((state) => state.modal.isOpen);
  const undergradDegreeList = useSelector(
    (state) => state.dataLists.undergradDegreeList
  );

  const filterOnlyChecksheets = (program) => {
    return !!checksheetList.find((checksheet) => {
      const checksheetInfo = checksheet.id.split('*');
      return (
        checksheetInfo[2] === program.acadPlanCode &&
        (!!program.subplan
          ? checksheetInfo[3] === program.subplan.acadSubPlanCode
          : checksheetInfo[3] === 'null')
      );
    });
  };

  const { mutate: getAllChecksheets } = useMutation({
    mutationFn: checksheetGetAllByYear,
    onSuccess: async (data) => {
      const next = [];

      data.forEach((checksheet) => {
        const idYear = checksheet['sk'].S.split('*')[0];
        let lockedBy = {};

        if (!!checksheet['lockedBy']?.S) {
          lockedBy = JSON.parse(checksheet['lockedBy'].S);
        }

        const obj = {
          id: checksheet['sk'].S,
          checksheetObj: checksheet,
          catalogYear: idYear,
          status: checksheet['progress']?.S ?? null,
          updatedBy: checksheet['updatedBy'].S,
          updatedOn: checksheet['updatedOn'].S,
          lockedBy: lockedBy,
        };

        next.push(obj);
      });

      dispatch(setChecksheetList([...next]));
    },
  });

  // Save new checksheet to database
  const { mutate: copyChecksheet } = useMutation({
    mutationFn: checksheetCopy,
    onSuccess: async (data, { token }) => {
      getAllChecksheets({ year, token });
    },
  });

  const handleCreateChecksheet = async () => {
    if (selectedRadio === 'blankChecksheet') {
      navigate(
        `/checksheet?id=${year}*${currentChecksheet.mainOwner.collegeAcadOrg}*${currentChecksheet.program.acadPlanCode}*${!!currentChecksheet.program.subplan ? currentChecksheet.program.subplan.acadSubPlanCode : 'null'}`
      );
      handleClose();
    } else if (selectedChecksheet) {
      const jsonData = {
        checksheetfrom: selectedChecksheet.id,
        acadplanto: currentChecksheet.program.acadPlanCode,
        acadsubplanto: currentChecksheet.program.subplan
          ? currentChecksheet.program.subplan.acadSubPlanCode
          : 'null',
        collegeto: currentChecksheet.mainOwner.collegeAcadOrg,
        departmentto: currentChecksheet.mainOwner.departmentAcadOrg,
        nameto:
          currentChecksheet.program.acadPlanDescription +
          currentChecksheetDegree,
        programtypeto:
          currentChecksheet.program.degreeDescriptionShort ??
          currentChecksheet.program.acadPlanType,
        yearto: year,
      };

      console.log('JSON: ', jsonData);

      const token = await getAccessToken();
      copyChecksheet({ jsonData: jsonData, token: token });

      handleClose();
    } else {
      alert('Program not selected');
    }
  };

  // const handleSelectToProgramYear = (e) => {
  //   setToProgramYear(e.target.value);
  // };

  const handleSelectRadio = (e) => {
    setSelectedRadio(e.target.value);
  };

  const handleSelectChecksheet = (program) => {
    const cs = checksheetList.find(
      (checksheet) =>
        checksheet.id.split('*')[2] === program.acadPlanCode &&
        (!!program.subplan
          ? checksheet.id.split('*')[3] === program.subplan.acadSubPlanCode
          : checksheet.id.split('*')[3] === 'null')
    );

    console.log(cs);
    console.log(program);
    setSelectedProgram(program);
    setSelectedChecksheet(cs);
  };

  const handleClearSelectedChecksheet = () => {
    setSearchString('');
    setSelectedChecksheet(null);
  };

  useEffect(() => {
    if (currentChecksheet && currentChecksheet.program) {
      const dds = currentChecksheet.program.degreeDescriptionShort
        ? currentChecksheet.program.degreeDescriptionShort === 'CERT'
          ? ', Certificate'
          : `, ${currentChecksheet.program.degreeDescriptionShort}`
        : currentChecksheet.program.acadPlanType === 'MIN'
          ? ', Minor'
          : null;

      setCurrentChecksheetDegree(dds);
    }
  }, [currentChecksheet]);

  useEffect(() => {
    if (selectedProgram) {
      const dds = selectedProgram.degreeDescriptionShort
        ? selectedProgram.degreeDescriptionShort === 'CERT'
          ? ', Certificate'
          : `, ${selectedProgram.degreeDescriptionShort}`
        : selectedProgram.acadPlanType === 'MIN'
          ? ', Minor'
          : null;

      setSelectedProgramDegree(dds);
    }
  }, [selectedProgram]);

  useEffect(() => {
    if (!isModalOpen) {
      setSelectedRadio(null);
      setSearchString('');
      setSelectedChecksheet(null);
    }
  }, [isModalOpen]);

  if (!currentChecksheet || !currentChecksheet.program)
    return <div>Loading...</div>;

  return (
    <div className="p-6" style={{ width: '832px' }}>
      <div className="fs-4 fw-bold">
        {currentChecksheet.program.acadPlanDescription}
        {currentChecksheetDegree}
      </div>
      <div className="fs-1 fw-bold mb-4">New checksheet</div>

      <form className="uds-form">
        <fieldset>
          <div className="form-check">
            <input
              className="form-check-input"
              type="radio"
              id="blankChecksheet"
              data-ga-input="radio button"
              data-ga-input-name="onclick"
              data-ga-input-event="select"
              data-ga-input-action="click"
              data-ga-input-region="main content"
              data-ga-input-section="Create from blank checksheet"
              value="blankChecksheet"
              checked={selectedRadio === 'blankChecksheet'}
              onChange={(e) => handleSelectRadio(e)}
            />
            <label className="form-check-label" htmlFor="blankChecksheet">
              <div>Create from blank checksheet</div>
              <div className="fw-normal text-gray-6">
                Build program requirements from scratch
              </div>
            </label>
          </div>
          <div className="form-check">
            <input
              className="form-check-input"
              type="radio"
              id="existingProgram"
              data-ga-input="radio button"
              data-ga-input-name="onclick"
              data-ga-input-event="select"
              data-ga-input-action="click"
              data-ga-input-region="main content"
              data-ga-input-section="Create from existing program"
              value="existingChecksheet"
              checked={selectedRadio === 'existingChecksheet'}
              onChange={(e) => handleSelectRadio(e)}
            />
            <label className="form-check-label" htmlFor="existingProgram">
              <div>Create from existing program</div>
              <div className="fw-normal text-gray-6">
                Start with an existing checksheet and make modifications as
                needed. Recommended only for similar programs with shared
                requirements.
              </div>
            </label>
          </div>
        </fieldset>
      </form>

      {selectedRadio === 'existingChecksheet' && (
        <div className="mb-9">
          {!selectedChecksheet ? (
            <>
              <div className="fw-bold lh-1 mb-1">Search programs</div>
              <div className="position-relative">
                <input
                  placeholder="Search by program title or plan code"
                  value={searchString}
                  onChange={(e) => setSearchString(e.target.value)}
                  className="border border-gray-5 ps-2 pe-6 py-1 w-100"
                />
                {searchString && (
                  <div
                    className="position-absolute border border-top-0 border-gray-5 w-100 overflow-y-auto"
                    style={{ maxHeight: '198px', zIndex: 1 }}
                  >
                    {undergradDegreeList
                      .filter(filterOnlyChecksheets)
                      .filter(
                        (program) =>
                          program.acadPlanCode !==
                            currentChecksheet.program.acadPlanCode &&
                          (program.acadPlanCode
                            .toLowerCase()
                            .includes(searchString.toLowerCase()) ||
                            program.acadPlanDescription
                              .toLowerCase()
                              .includes(searchString.toLowerCase()))
                      )
                      .map((program, index, array) => (
                        <div key={index} className="bg-white px-2">
                          <div
                            className={classNames(
                              index !== array.length - 1 && 'border-bottom',
                              'border-gray-5 py-2'
                            )}
                            onClick={() => handleSelectChecksheet(program)}
                            role="button"
                          >
                            <SearchProgram
                              program={program}
                              checksheet={currentChecksheet}
                            />
                          </div>
                        </div>
                      ))}
                  </div>
                )}
              </div>
            </>
          ) : (
            <>
              <div className="bg-gray-1 border border-gray-3 w-100 mb-3">
                <div className="p-2">
                  <div className="d-flex justify-content-between mb-1">
                    <div className="fw-bold" style={{ fontSize: '18px' }}>
                      {selectedProgram.acadPlanDescription}
                      {selectedProgramDegree}
                    </div>
                    <div className="d-flex gap-3 align-items-center">
                      <div>{selectedProgram.acadPlanCode}</div>
                      <div
                        className="bg-white border border-gray-3 lh-1 rounded-circle"
                        style={{
                          height: 'min-content',
                          padding: '3px 6px 5px',
                        }}
                        onClick={handleClearSelectedChecksheet}
                        role="button"
                      >
                        <FontAwesomeIcon
                          icon={faXmark}
                          style={{ height: '12px', width: '12px' }}
                        />
                      </div>
                    </div>
                  </div>

                  {selectedProgram.subplan && (
                    <div className="d-flex gap-2 justify-content-between">
                      <div className="fw-bold">
                        {selectedProgram.subplan.description}
                        <span className="subplan-tag ms-1">Subplan</span>
                      </div>
                      <div style={{ margin: '3px 50px 0 0' }}>
                        {selectedProgram.subplan.acadSubPlanCode}
                      </div>
                    </div>
                  )}

                  <div className="d-flex gap-1 align-items-center mb-1">
                    <FontAwesomeIcon
                      icon={faBuildingColumns}
                      className="text-gray-5"
                      width={'16px'}
                    />
                    {selectedProgram.owners.map((owner, index, array) => (
                      <Fragment key={owner.collegeAcadOrg}>
                        {owner.collegeDescription}
                        {index !== array.length - 1 && ', '}
                      </Fragment>
                    ))}
                  </div>
                  <div className="d-flex gap-1 align-items-center">
                    <FontAwesomeIcon
                      icon={faLocationDot}
                      className="text-gray-5"
                      width={'16px'}
                    />
                    {selectedProgram.campusesOffered.map(
                      (campus, index, array) => (
                        <Fragment key={campus.campusCode}>
                          {
                            StaticData.campusUndergradList.find(
                              (camp) => camp.value === campus.campusCode
                            ).label
                          }
                          {index !== array.length - 1 && ', '}
                        </Fragment>
                      )
                    )}
                  </div>
                </div>
              </div>
              {/* <div>
                <div className="fw-bold mb-1">Catalog year</div>
                <select
                  value={toProgramYear}
                  onChange={handleSelectToProgramYear}
                  styles={{
                    width: '150px',
                  }}
                  className="text-gray-7 p-1"
                >
                  {StaticData.yearList.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </div> */}
            </>
          )}
        </div>
      )}

      <div className="d-flex justify-content-between align-items-center">
        <Button
          color="dark"
          label="Cancel"
          size="medium"
          onClick={handleClose}
        />
        <Button
          color="gold"
          label="Create checksheet"
          size="medium"
          onClick={handleCreateChecksheet}
          disabled={selectedRadio !== 'blankChecksheet' && !selectedChecksheet}
        />
      </div>
    </div>
  );
};

export default NewChecksheet;
